@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
header {
  position: absolute;
  background: none;
  height: auto;
  padding-top: 0; }
  header a.logo img {
    display: none; }

#gnavi {
  margin-top: 30px; }

.mainvisual {
  padding-top: 160px;
  padding-bottom: 20vw; }
  @media screen and (max-width: 800px) {
    .mainvisual {
      background-image: url(/inc/image/top/bg_mainvisual.jpg);
      background-size: cover;
      background-position: center center;
      background-attachment: fixed;
      padding-top: 160px;
      background-attachment: scroll; } }
  @media screen and (max-width: 600px) {
    .mainvisual {
      padding-top: 80px;
      padding-bottom: 0;
      background-image: url(/inc/image/top/bg_mainvisual_mb.jpg);
      background-attachment: scroll; } }
  .mainvisual div.backgroundwrap {
    content: "";
    display: block;
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: 10vw;
    height: 100vh;
    z-index: -99;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#447ad2+0,7abcff+100 */
    background: #447ad2;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #447ad2 0%, #7abcff 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #447ad2 0%, #7abcff 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #447ad2 0%, #7abcff 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#447ad2', endColorstr='#7abcff',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */
    opacity: 0.8;
    mix-blend-mode: overlay;
    display: none\9;
    display: none\0; }
    @media all and (-ms-high-contrast: none) {
      .mainvisual div.backgroundwrap {
        display: none\0; } }
    @media all and (-ms-high-contrast: none) {
      .mainvisual div.backgroundwrap *::-ms-backdrop {
        display: none\0; } }
    @supports (-ms-ime-align: auto) {
      .mainvisual div.backgroundwrap {
        /* Edge のみ適用される */ }
        .mainvisual div.backgroundwrap *::-ms-backdrop {
          display: none\0; } }
  .mainvisual #video-background {
    /*  動画を背景に設定するスタイルシート  */
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100; }
  .mainvisual img.logo {
    display: block;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 75px; }
    @media screen and (max-width: 600px) {
      .mainvisual img.logo {
        width: 80%;
        margin-bottom: 70px; } }
  .mainvisual h1 {
    text-align: center;
    text-shadow: 0 0 5px #081d3b;
    color: #fff;
    font-family: "Times New Roman";
    font-size: 26px;
    margin-bottom: 22px;
    padding: 0 20px; }
    @media screen and (max-width: 600px) {
      .mainvisual h1 {
        font-size: 29px;
        padding: 0 10px; } }
    .mainvisual h1 br {
      display: none; }
      @media screen and (max-width: 800px) {
        .mainvisual h1 br {
          display: block; } }
  .mainvisual p.desc {
    text-align: center;
    text-shadow: 0 0 5px #081d3b;
    color: #fff;
    line-height: 22px;
    padding: 0 20px; }
    @media screen and (max-width: 600px) {
      .mainvisual p.desc {
        display: none; } }
    @media screen and (max-width: 800px) {
      .mainvisual p.desc br {
        display: none; } }
  .mainvisual .information {
    text-shadow: 0 0 5px #081d3b; }
    @media screen and (max-width: 600px) {
      .mainvisual .information {
        background-color: rgba(0, 0, 0, 0.5);
        margin-top: 50px;
        padding-bottom: 120px; } }
    .mainvisual .information .news, .mainvisual .information .feature, .mainvisual .information .our_stories {
      margin-top: 60px;
      display: inline-block;
      width: 50%;
      float: left; }
      @media screen and (max-width: 600px) {
        .mainvisual .information .news, .mainvisual .information .feature, .mainvisual .information .our_stories {
          width: 100%;
          float: none;
          margin-top: 30px; } }
      .mainvisual .information .news h2, .mainvisual .information .feature h2, .mainvisual .information .our_stories h2 {
        color: #fff;
        font-family: "Times New Roman";
        font-size: 25px;
        display: inline-block;
        padding-bottom: 3px;
        margin-bottom: 15px;
        border-bottom: solid 1px #d7053d; }
      .mainvisual .information .news a, .mainvisual .information .feature a, .mainvisual .information .our_stories a {
        color: #fff; }
        .mainvisual .information .news a.more, .mainvisual .information .feature a.more, .mainvisual .information .our_stories a.more {
          display: inline-block;
          margin-top: 20px; }
      .mainvisual .information .news p, .mainvisual .information .feature p, .mainvisual .information .our_stories p {
        color: #fff; }
    .mainvisual .information .news {
      padding-left: 57px;
      padding-right: 125px; }
      @media screen and (max-width: 1000px) {
        .mainvisual .information .news {
          padding: 0 20px; } }
      @media screen and (max-width: 600px) {
        .mainvisual .information .news {
          border-bottom: solid 1px #677889;
          padding-bottom: 30px; } }
      .mainvisual .information .news ul li {
        list-style-type: none;
        margin-bottom: 20px; }
        .mainvisual .information .news ul li a .title {
          display: inline-block;
          width: 70%; }
        .mainvisual .information .news ul li a .date {
          display: inline-block;
          width: 30%; }
    .mainvisual .information .feature {
      padding-left: 150px;
      padding-right: 70px; }
      @media screen and (max-width: 1000px) {
        .mainvisual .information .feature {
          padding: 0 20px; } }
    .mainvisual .information .our_stories {
      padding-right: 57px; }
      @media screen and (max-width: 1000px) {
        .mainvisual .information .our_stories {
          padding: 0 20px; } }
      @media screen and (max-width: 600px) {
        .mainvisual .information .our_stories {
          border-bottom: solid 1px #677889;
          padding-bottom: 30px; } }
      .mainvisual .information .our_stories ul li {
        list-style-type: none;
        margin-bottom: 20px; }
        .mainvisual .information .our_stories ul li a .title {
          display: inline-block;
          width: 80%; }
          @media screen and (max-width: 1000px) {
            .mainvisual .information .our_stories ul li a .title {
              width: 70%; } }
        .mainvisual .information .our_stories ul li a .date {
          display: inline-block;
          width: 20%; }
          @media screen and (max-width: 1000px) {
            .mainvisual .information .our_stories ul li a .date {
              width: 30%; } }

.triangle-section {
  margin-top: -24vw; }
  @media screen and (max-width: 600px) {
    .triangle-section {
      margin-top: -18vw; } }
  .triangle-section .title-triangle h2 {
    margin-bottom: unset; }
  .triangle-section .title-triangle h1, .triangle-section .title-triangle h2 {
    line-height: 50px; }
  .triangle-section section.section-content .bg-section {
    background-color: transparent !important; }
    @media screen and (max-width: 800px) {
      .triangle-section section.section-content .bg-section {
        background-image: none; }
        .triangle-section section.section-content .bg-section .title {
          margin-bottom: 0; }
        .triangle-section section.section-content .bg-section .image {
          width: 100vw;
          position: relative;
          left: 50%;
          right: 50%;
          margin-left: -50vw;
          margin-right: -50vw;
          margin-bottom: 50px; }
        .triangle-section section.section-content .bg-section .desc p .more {
          margin-top: 0; } }
    @media screen and (max-width: 800px) {
      .triangle-section section.section-content .bg-section.reverse .image {
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        margin-bottom: 50px; } }
    .triangle-section section.section-content .bg-section.bg_story_01 {
      background-image: url(/inc/image/top/img_reach_line09.png), url(/inc/image/common/bg_triangle_rw.svg) !important;
      background-size: 524px 600px,cover;
      background-position: right bottom,center top;
      background-color: #E6E6E6 !important; }
      @media screen and (max-width: 600px) {
        .triangle-section section.section-content .bg-section.bg_story_01 {
          background-image: url(/inc/image/common/bg_triangle_cw.svg) !important;
          background-size: cover;
          background-position: center top; } }
    .triangle-section section.section-content .bg-section.bg_story_02 {
      background-image: url(/inc/image/top/img_reach_line02.png), url(/inc/image/common/bg_triangle_lg.svg) !important;
      background-size: 603px 562px,cover;
      background-position: left bottom,center top; }
      @media screen and (max-width: 600px) {
        .triangle-section section.section-content .bg-section.bg_story_02 {
          background-image: url(/inc/image/common/bg_triangle_cg.svg) !important;
          background-size: cover;
          background-position: center top; } }
    .triangle-section section.section-content .bg-section.bg_story_03 {
      background-image: url(/inc/image/top/img_reach_line03.png), url(/inc/image/common/bg_triangle_rw.svg) !important;
      background-size: 494px 600px,cover;
      background-position: right bottom,center top; }
      @media screen and (max-width: 600px) {
        .triangle-section section.section-content .bg-section.bg_story_03 {
          background-image: url(/inc/image/common/bg_triangle_cw.svg) !important;
          background-size: cover;
          background-position: center top; } }
    .triangle-section section.section-content .bg-section.bg_story_04 {
      background-image: url(/inc/image/top/bg_logo.png), url(/inc/image/common/bg_triangle_lg.svg) !important;
      background-size: 690px 248px,cover;
      background-position: left bottom,center top; }
      @media screen and (max-width: 600px) {
        .triangle-section section.section-content .bg-section.bg_story_04 {
          background-image: url(/inc/image/common/bg_triangle_cg.svg) !important;
          background-size: cover;
          background-position: center top; } }
